<template>
  <admin>
    <metatag title="Online Reservations"></metatag>

    <page-header>
      <template v-slot:action>
        <v-row>
          <v-col cols="6">
            <v-btn :block="$vuetify.breakpoint.smAndDown" large color="info" exact
              :to="{ name: 'online-reservations.upload' }">
              <v-icon small left>mdi-cloud-upload</v-icon>
              <span v-text="'Upload'"></span>
            </v-btn>
          </v-col>
          <v-col cols="6">
            <can :code="['admin']">
              <v-btn :block="$vuetify.breakpoint.smAndDown" large color="grey" class="white--text" exact
                @click="downloadReservation">
                <v-icon small left>mdi-cloud-download</v-icon>
                <span v-text="'Download'"></span>
              </v-btn>
            </can>
          </v-col>
        </v-row>
      </template>
    </page-header>

    <v-row v-if="userRoles">
      <v-col>
        <v-card class="my-4">
          <toolbar-menu class="reservations-toolbar" :class="{ 'reservations-toolbar-admin': hasAccess('admin') }"
            ref="toolbar" :filter-model="true" :filter-dealer="hasAccess('admin')" :filter-status="true"
            :filter-action="true" :items.sync="tabletoolbar" @update:search="setSearch"
            @update:status="handleFilterStatus" @update:action="handleFilterAction" @update:model="handleFilterModel"
            @update:dealer="handleFilterDealer"></toolbar-menu>
          <v-card-text class="pa-0">
            <v-data-table :headers="tableData.headers" :items="items" :loading="tableData.loading"
              :options.sync="options" :server-items-length="total" :items-per-page="5" color="primary" item-key="id"
              class="text-no-wrap">
              <template v-slot:progress><span></span></template>mplate>

              <template v-slot:item.id="{ item }">
                <div class="text-no-wrap">
                  <!-- Preview -->
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn @click="previewItem(item)" text color="primary" icon v-on="on">
                        {{ item.id }}
                      </v-btn>
                    </template>
                    <span v-text="'Preview'"></span>
                  </v-tooltip>
                </div>
              </template>
              <template v-slot:item.address="{ item }">
                <small>{{ item.street }} {{ item.barangay }} {{ item.municipality }}</small><br />
                <small>{{ item.province }} {{ item.zipcode }}</small><br />
              </template>

              <template v-slot:item.name="{ item }">
                <small>{{ item.title }} {{ item.first_name }} {{ item.last_name }}</small> <br />
                <small>{{ item.mobile }}</small> <br />
                <small>{{ item.email }}</small>
              </template>

              <template v-slot:item.dealer="{ item }">
                <small>{{ item.dealer_name ? item.dealer_name : "-" }}</small>
              </template>

              <template v-slot:item.vehicle="{ item }">
                <small>{{ item.model_name }}</small> <br />
                <small>{{ item.variant_name }}</small> <br />
                <small>{{ item.color }}</small>
              </template>

              <template v-slot:item.reservation_date="{ item }">
                <small>{{ item.reserved_at ? formatReservationDate(item.reserved_at) : "" }}</small>
              </template>

              <template v-slot:item.sc_assigned="{ item }">
                <small>{{
                  item.assigned_sc_user
                    ? `${item.assigned_sc_user.first_name} ${item.assigned_sc_user.last_name}`
                    : ""
                }}</small>
              </template>

              <template v-slot:item.sc_assigned_date="{ item }">
                <small>{{ item.sc_assigned_at ? formatDate(item.sc_assigned_at) : "" }}</small>
              </template>

              <template v-slot:item.date_updated="{ item }">
                <small>{{ item.updated_at ? formatDate(item.updated_at) : "" }}</small>
              </template>

              <template v-slot:item.status="{ item }">
                <v-chip label>
                  {{ item.status ? item.status.label : "" }}
                </v-chip>
              </template>

              <template v-slot:item.raction="{ item }">
                <v-chip label>
                  {{ item.action ? item.action.label : "" }}
                </v-chip>
              </template>

              <template v-slot:item.action="{ item }">
                <v-chip label>
                  {{ item.action }}
                </v-chip>
              </template>
              <template v-slot:item.editAction="{ item }">
                <div class="text-no-wrap">
                  <!-- Edit -->
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn @click="editItem(item)" text icon v-on="on">
                        <v-icon small>mdi-pencil</v-icon>
                      </v-btn>
                    </template>
                    <span v-text="'Edit'"></span>
                  </v-tooltip>
                  <!-- Edit -->
                </div>
              </template>
              <template v-slot:loading>
                <v-slide-y-transition mode="out-in">
                  <skeleton-avatar-table></skeleton-avatar-table>
                </v-slide-y-transition>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <edit @close:edit="cancelEdit" :edit="actionDialog" :item="form" v-if="actionDialog"></edit>
    <preview @close:preview="closePreview" :preview="previewDialog" :item="selectedItem"></preview>
  </admin>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import * as helpers from "@/core/helpers";
import { debounce } from "lodash";
import Edit from "./Edit.vue";
import Preview from "./partials/Preview.vue";

export default {
  components: {
    Preview,
    Edit,
  },
  data() {
    return {
      statusFilter: "",
      actionFilter: "",
      modelFilter: "",
      actionDialog: false,
      loadingSave: false,
      tableData: {
        loading: false,
        headers: [
          {
            text: "ID",
            align: "start",
            value: "id",
          },
          {
            text: "Customer #",
            align: "left",
            value: "customer_id",
            class: "text-no-wrap",
          },
          { text: "Name", align: "left", value: "name", class: "text-no-wrap" },
          { text: "Adress", value: "address", class: "text-no-wrap" },
          { text: "Vehicle", value: "vehicle", class: "text-no-wrap" },
          { text: "Payment Method", value: "payment_method", class: "text-no-wrap" },
          {
            text: "Reservation Date",
            value: "reservation_date",
            class: "text-no-wrap",
          },
          { text: "Sales Consultant", value: "sc_assigned", class: "text-no-wrap" },
          {
            text: "SC Assigned Date",
            value: "sc_assigned_date",
            class: "text-no-wrap",
          },
          { text: "Dealer", value: "dealer", class: "text-no-wrap" },
          { text: "Status", value: "status", class: "text-no-wrap" },
          { text: "Action", value: "raction", class: "text-no-wrap" },
          {
            text: "Date Updated",
            value: "date_updated",
            class: "text-no-wrap",
          },
          {
            text: "Actions",
            align: "center",
            value: "editAction",
            class: "muted--text text-no-wrap",
          },
        ],
      },
      tabletoolbar: {
        isSearching: false,
        search: null,
        type: "onlinereservation",
      },
      options: {},
      items: [],
      total: 0,
      form: {
        id: null,
        status: null,
        action: null,
        assigned_sc_user_id: null,
      },
      previewDialog: false,
      selectedItem: {},
    };
  },
  watch: {
    options: {
      handler() {
        const { sortBy, sortDesc, page, itemsPerPage } = this.options;
        this.getItems(page, itemsPerPage);
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters({
      data: "reservations/GET_RESERVATIONS",
      model: "models/GET_MODEL",
      userRoles: "auth/GET_ROLES",
      dealerFilter: "dealer/GET_DEALER",
    }),
  },
  async mounted() {
    await this.getUserType();
  },
  methods: {
    ...mapActions({
      getReservations: "reservations/list",
      getModel: "models/getModel",
      download: "reservations/download",
      getUserType: "auth/getUserType",
    }),

    setSearch: debounce(async function (e) {
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;

      this.getItems(page, itemsPerPage, e.target.value);
    }, 300),

    hasAccess(role) {
      let user_roles = this.userRoles.map(a => a.slug);

      return user_roles.includes(role);
    },

    getItems(page, itemsPerPage, q = "") {
      let data = {
        page,
        per_page: itemsPerPage,
        q,
      };
      if (this.statusFilter) {
        data.status = this.statusFilter;
      }
      if (this.actionFilter) {
        data.action = this.actionFilter;
      }
      if (this.modelFilter) {
        data.model = this.modelFilter;
      }
      if (this.dealerFilter) {
        data.dealer = this.dealerFilter;
      }
      this.getReservations(data).then((data) => {
        this.items = this.data.data;
        this.total = this.data.meta.total;
        this.tableData.loading = false;
        this.$refs.toolbar.items.isSearching = false;
      });
    },

    formatDate(item) {
      return helpers.format_date(item);
    },

    formatReservationDate(item) {
      return helpers.format_date(item, "YYYY-MM-DD");
    },

    showTip(e) {
      document.querySelector(".tooltip-message").classList.remove("d-none");
    },

    editItem(item) {
      this.actionDialog = true;
      this.form = {
        id: item.id,
        status: item.status ? item.status.code : "",
        action: item.action ? item.action.code : "",
        assigned_sc_user_id: item.assigned_sc_user ? item.assigned_sc_user.id : "",
        dealer_id: item.dealer_id,
      };
    },

    cancelEdit() {
      this.actionDialog = false;
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;

      this.getItems(page, itemsPerPage, "");

      this.form = {
        id: null,
        status: null,
        action: null,
        assigned_sc_user_id: null,
      };
    },

    async handleFilterStatus(val) {
      this.statusFilter = val;
      await this.getItems(1, 5, "");
    },

    async handleFilterAction(val) {
      this.actionFilter = val;
      await this.getItems(1, 5, "");
    },

    async handleFilterModel(val) {
      this.modelFilter = val;
      if (val) {
        await this.getModel(val);
        this.modelFilter = this.model.name;
      }
      await this.getItems(1, 5, "");
    },

    async handleFilterDealer() {
      await this.getItems(1, 5, "");
    },

    async downloadReservation() {
      await this.download();
    },

    closePreview() {
      this.previewDialog = false;
      this.selectedItem = {};
    },

    previewItem(item) {
      this.previewDialog = true;
      this.selectedItem = item;
    },
  },
};
</script>

<style></style>
