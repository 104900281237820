<template>
  <v-dialog v-model="isShow" persistent max-width="600px" class="overflow-hidden">
    <v-card class="pa-4">
      <v-card-title>
        <h2 title="Edit" class="mb-1">Edit #{{ form.id }}</h2>
      </v-card-title>
      <v-card-text class="overflow-y-auto">
        <v-row>
          <v-col cols="12">
            <SalesConsultant :assigned_sc_user_id="form.assigned_sc_user_id" :dealer="form.dealer_id" />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-select
              append-icon="mdi-chevron-down"
              :items="statuses"
              item-text="title"
              item-value="slug"
              label="Status"
              outlined
              clearable
              hide-details
              clear-icon="mdi-close-circle-outline"
              background-color="selects"
              v-model="form.status"
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-select
              append-icon="mdi-chevron-down"
              :items="actions"
              item-text="title"
              item-value="slug"
              label="Action"
              outlined
              clearable
              hide-details
              clear-icon="mdi-close-circle-outline"
              background-color="selects"
              v-model="form.action"
            ></v-select>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          large
          color="grey"
          exact
          class="ma-1 white--text px-5"
          @click="cancelEdit"
          :disabled="loadingSave"
        >
          Cancel
        </v-btn>

        <v-btn
          large
          exact
          color="green darken-1"
          class="ma-1 white--text px-5"
          @click="save"
          :loading="loadingSave"
          :disabled="loadingSave"
        >
          <v-icon left>mdi-content-save</v-icon>
          Submit
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import * as helpers from "@/core/helpers";
import { mapActions, mapGetters } from "vuex";
import { find } from "lodash";
import SalesConsultant from "@/components/SalesConsultant/SalesConsultant";
import Address from "@/components/Address/Address";
export default {
  components: {
    SalesConsultant,
  },
  props: {
    edit: {
      required: false,
      type: Boolean,
      default() {
        return false;
      },
    },
    item: {
      required: true,
      type: Object,
    },
  },
  watch: {
    edit: {
      immediate: true,
      handler(val) {
        this.isShow = val;
      },
    },
    item: {
      immediate: true,
      handler(val) {
        this.form = val;
      },
    },
  },
  async mounted() {
    await this.getStatuses();
    await this.getActions();
  },
  data() {
    return {
      loadingSave: false,
      isShow: false,
    };
  },
  computed: {
    ...mapGetters({
      consultant: "report/GET_SALES_CONSULTANT",
      statuses: "reservations/GET_RESERVATION_STATUSES",
      actions: "reservations/GET_RESERVATION_ACTIONS",
    }),
  },
  methods: {
    ...mapActions({
      saveReservation: "reservations/save",
      getStatuses: "reservations/getReservationStatuses",
      getActions: "reservations/getReservationActions",
    }),
    cancelEdit() {
      this.$emit("close:edit");
    },
    async save() {
      this.loadingSave = true;
      const form = {
        id: this.form.id,
        data: {
          status: this.form.status,
          action: this.form.action,
        },
      };
      if (this.consultant) {
        form.data.assigned_sc_user_id = this.consultant;
      }

      await this.saveReservation(form);
      this.loadingSave = false;
      this.cancelEdit();
    },
  },
};
</script>
